<template>
    <o-data-lookup :data-object="dsResources" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <o-column field="ID" width="80"></o-column>
        <o-column field="OrgUnit" width="300"></o-column>
        <o-column field="FunctionRole" width="175"></o-column>
        <o-column field="Responsible" width="200"></o-column>
        <o-column field="FromDate" width="100"></o-column>
        <o-column field="ToDate" width="100"></o-column>
    </o-data-lookup>
</template>


<script setup>    

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsResources = $getDataObjectById("dsResource_Resources");    
</script>
